@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&family=Poppins:wght@300;400;500;600;700&family=Roboto:ital,wght@0,500;0,700;1,300&display=swap');

$secondary: #161719;
$grey: #252628;
$grey200: #1c1d1f;
$grey300: #191d1e;
$grey400: #1e1f21;
$grey500: #343A42;
$primary: #43a0ff;
$lighten: #808080;
$orange: #fa734b;
$yellow: #ecd25a;

$fonts: -apple-system, Poppins, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

/*global styles*/
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $fonts;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $secondary;
  font-size: 15px;
  color: #ffffff;
  overflow: hidden;
}

input, textarea {
  background: $grey200;
  border-radius: 10px;
  color: #ffffff;
  padding: 6px 14px;
  border: 1px solid transparent;
  line-height: 24px;
  font-weight: normal;
  font-size: 14px;
  outline: currentcolor none medium;

  &:focus {
    border-color: lighten($grey200, 8%);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.text-h5, .text-h6 {
  margin-top: 0;
  margin-bottom: 0;
}

.conversation-title, .conversation-count {
  margin-top: 0;
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: 400;
}

.conversation-count {
  font-size: 16px;
}

.conversation-title {
  font-size: 17px;
}

.text-h6 {
  font-weight: 500;
  font-size: 18px;
}

.text-h5 {
  font-size: 14px;
  font-weight: 400;
}

.text-white {
  color: #ffffff;
}

.bg-orange {
  background: $orange !important;
}

.center, .start, .end, .space-between {
  display: flex;
  align-items: center;
}

.center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.start {
  justify-content: start;
}

.end {
  justify-content: flex-end;
}

.small {
  font-size: 12px;
}

.w100 {
  width: 100%;
}

.ml-2 {
  margin-left: 20px;
}

.mr-1 {
  margin-right: 10px;
}

.ps-8 {
  padding-right: 8px;
  padding-left: 8px;
}

.ps-12 {
  padding-right: 12px;
  padding-left: 12px;
}

.ps-16 {
  padding-right: 16px;
  padding-left: 16px;
}

.lh-0 {
  line-height: 0;
}

.pointer {
  cursor: pointer;
}

ul {
  list-style: none;
}

.column {
  flex-direction: column;
}

.lighten {
  color: $lighten;
}


/* ===== Scrollbar CSS ===== */
/* Firefox */
textarea {
  scrollbar-width: thin;
  scrollbar-color: #272a2f transparent;
}

/* Chrome, Edge, and Safari */
textarea::-webkit-scrollbar {
  width: 10px;
}

textarea::-webkit-scrollbar-track {
  background: transparent;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #272a2f;
  border-radius: 0;
  border: 3px solid transparent;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.messages {
  scrollbar-width: thin;
  scrollbar-color: #272a2f transparent;
}

/* Chrome, Edge, and Safari */
.messages::-webkit-scrollbar {
  width: 10px;
}

.messages::-webkit-scrollbar-track {
  background: transparent;
}

.messages::-webkit-scrollbar-thumb {
  background-color: #272a2f;
  border-radius: 0;
  border: 3px solid transparent;
}


/*Components and page styles*/

.App {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
}

.search-box {
  position: relative;
  width: 100%;
}

.layout-left, .layout-center, .layout-right {
  height: 100%;
  min-height: 100vh;
}

.layout-right {
  min-width: 20vw;
}

.layout-left {
  min-width: 24vw;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.layout-center {
  border-left: 2px solid #1c1d1f;
  width: 100%;
}

.column_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.column_container {
  height: 100vh;
  padding: 24px 18px 0;

  &.details {
    background: $grey300;
  }

  .column_header {
    margin-bottom: 32px;
  }
}

.avatar {
  img {
    width: 100%;
    height: 100%;
    user-select: none;
  }
}

.avatar-menu {
  display: none;
  width: 260px;
  background: #292a2d;
  border-radius: 16px;
  position: relative;
  bottom: 12px;
}

.avatar-menu.active {
  display: block;
  animation: scaleUp 300ms ease-in-out forwards;
  transform-origin: top center;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 0;
  padding: 16px;
  border-bottom: 1px solid rgba(65, 67, 72, 0.7);
  gap: 12px;
  font-size: 14px;
  -webkit-user-select: none;
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:nth-child(1){
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  &:last-child{
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-bottom: 0;
  }

  &:hover {
    background: rgba(65, 67, 72, 0.7);
  }

  &:active {
    background: rgb(65, 67, 72);
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      stroke: $primary;
    }
  }
}

.border-avatar {
  background: $grey;
  padding: 5px;
  border-radius: 15px;
  position: relative;
  cursor: pointer;

  &:active {
    opacity: .9;

    img {
      opacity: .9;
    }
  }
}

.border-avatar::before {
  content: "";
  position: absolute;
  display: inline-block;
  height: 15px;
  width: 2px;
  background: #43a0ff;
  border-radius: 25px;
  top: calc(50% - 6px);
  left: 0;
}

.avatar-initial, .avatar-count {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

.avatar-initial {
  background: $primary;
}

.avatar-count {
  background: $grey500;
}

svg.lighten {
  circle, path {
    stroke: $lighten;
  }
}

.icon-clickable {
  flex: 1;
  cursor: pointer;

  &:hover .lighten {
    svg {
      width: 22px;
      height: 22px;
    }

    path {
      stroke: #ffffff;
    }
  }
}

.icon-inner {
  position: absolute;
  top: 50%;
  left: 22px;
  transform: translateY(-50%);
  width: 1em;
  height: 1em;
}

.typing-icon {
  width: 18px;
  height: 18px;

  & path:nth-child(1) {
    stroke: transparent;
  }

  & path:nth-child(2) {
    fill: $primary;
  }
}

.audio-icon {
  width: 17px;
  height: 17px;

  path {
    stroke: #ffffff;
  }
}

.dot-separator {
  background: lighten($grey, 5%);
  border-radius: 50%;
  width: 4px;
  height: 4px;
}

.notification {
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-weight: 600;
  font-size: 11px;
  background: $orange;
  border-radius: 50%;
}

.chat-header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  box-shadow: 0 10px 6px -5px $secondary;
}

.recent-message {
  width: 100%;
  padding: 8px 12px;
  border-radius: 12px;
  user-select: none;
  cursor: pointer;
  margin-bottom: 5px;

  &:hover, &.active {
    background: $grey;
  }

  &:active {
    background: $grey400;
  }
}

.message-label {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
}

.discussion-name {
  font-size: 14px;
}

.messages {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 30px;
  overflow-y: auto;
  height: calc(100vh - 100px);
}

.message {
  position: relative;
  font-size: 13px;
  padding: 10px 12px;
  width: max-content;
  margin: 5px 0;

  &.sent {
    background: $primary;
    border-radius: 20px 0 20px 20px;
  }

  &.received {
    background: $grey500;
    border-radius: 0 20px 20px 20px;
  }
}

.sound-wave {
  height: 40px;
  width: 180px;
  display: flex;
  right: 10px;
  position: relative;
  padding: 2px 0;
  g {
    fill: $yellow;
  }
}

.play-icon {
  width: 33px;
  height: 33px;

  path {
    fill: #ffffff;
  }
}

.message-image {
  display: flex;
  height: 250px;
  position: relative;
  max-width: 400px;
  gap: 8px;
  img {
    border-radius: 15px;
  }
}

.message-container {
  display: flex;
  gap: 16px;

  .avatar {
    top: 2px;
    position: relative;
  }
}

.message-sender {
  font-size: 13px;
}

.message-time {
  font-size: 13px;
  font-weight: 400;
  color: $lighten;
}

.avatar-group {
  display: flex;
}

.field-box {
  display: flex;
  box-shadow: 0 -20px 19px -16px #161719;
  padding: 12px 32px;
  position: sticky;
  bottom: 0;
  background: #161719;

  textarea {
    width: 100%;
    resize: none;
    font-family: $fonts;
    font-size: 12px;
  }
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  left: 10px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: $lighten;
  color: $lighten;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
  top: 1px;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -8px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: $lighten;
  color: $lighten;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 8px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: $lighten;
  color: $lighten;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes scaleUp {
  0% {
    transform: scale(0)
  }
  80% {
    transform: scale(1.1)
  }
  100% {
    transform: scale(1)
  }
}

@keyframes dotFlashing {
  0% {
    background-color: $lighten;
  }
  50%,
  100% {
    background-color: lighten($lighten, 20%);
  }
}
